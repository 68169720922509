import React from 'react';

import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';
import usePageContext from 'utils/hooks/usePageContext';

import { ENTITY_TYPE, IMPRESSION_TRACKING_EVENTS } from 'constants/index';

import classes from './EntityMediaContent.module.scss';

const EntityMediaContent = ({ mediaContent, media, trackingData }) => {
  const { img, title, subTitle, description } = mediaContent || {};
  const { entity, entityType } = usePageContext();

  const primaryTitle = media?.title || title || entity?.name;
  const secondaryTitle = subTitle || entity?.metaInfo?.subTitle;
  const image = img || entity?.image?.small;
  const transformations =
    entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.AOS_LOGO;

  return (
    <div className={classes.mediaInfo}>
      <div className={classes.body}>
        {image ? (
          <div className={classes.body__imageWrapper}>
            <Image src={image} transformations={transformations} lazy={false} width={40} height={40} />
          </div>
        ) : (
          <div className={classes.imageWrapper}>
            <ImageAvatar name={primaryTitle} className={classes.image__avatar} width={40} height={40} fontSize={14} />
          </div>
        )}
        {(primaryTitle || secondaryTitle || description) && (
          <div className={classes.body__content}>
            <div className={classes.body__content_text}>
              {primaryTitle && (
                <Typography weight="medium" className={classes.title}>
                  <EntityName
                    name={primaryTitle}
                    entityType={entityType}
                    entity={entity}
                    trackingData={trackingData}
                    eventType={IMPRESSION_TRACKING_EVENTS.ENTITY_MEDIA_IMPRESSION}
                    isRaw
                  />
                </Typography>
              )}
              {!media?.title && secondaryTitle && (
                <Typography weight="regular" size={14} className={classes.subTitle}>
                  {secondaryTitle}
                </Typography>
              )}
            </div>
            {description && (
              <div className={classes.description}>
                <Typography weight="medium" size={12} className={classes.description__text}>
                  {description}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EntityMediaContent;
