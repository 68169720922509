/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import classnames from 'classnames';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import ImpressionTracker from 'utils/components/impressionTracker';
import classes from './Card.module.scss';

const Card = ({ children, linkProps, onClick, className, trackerProps }) => {
  const Wrapper = trackerProps ? ImpressionTracker : Fragment;

  return (
    <Wrapper {...(trackerProps && { trackerProps, className: classes.card })}>
      <div className={classnames(classes.card__wrapper, { [className]: !!className })} {...(onClick && { onClick })}>
        {children}
        {linkProps?.href && <Link {...linkProps} className={classes.link} />}
      </div>
    </Wrapper>
  );
};

export default Card;
