/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import MediaCard from 'components/Globals/MediaCard';
import ReactPortal from 'components/uiLibrary/ReactPortal';
import EntityMediaContent from 'components/Globals/Media/EntityMediaContent';
import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';

import { VIDEO_ACTION_BUTTONS, VIDEO_ATTRIBUTE_SLUGS, cueTvMediaUrlRegex } from 'constants/consts';
import { trackVideoClick } from 'utils/tracking';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useOnKeypress, { keyCodes } from 'utils/hooks/useOnKeypress';

import classes from './VideoPlayer.module.scss';

const ReactPlayerWrapper = dynamic(() => import('components/uiLibrary/ReactPlayerWrapper'), { ssr: false });

const VideoPlayerModal = ({ children, isPlaying, onClose, mediaContent, media }) => {
  const ref = useRef();

  const onCloseHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  useOnClickOutside(ref, onCloseHandler);
  useOnKeypress(keyCodes.ESCAPE, onCloseHandler);

  return (
    <ReactPortal>
      <div className={classes.videoplayer__modal}>
        <div className={classes.videoplayer__modal_content} ref={ref}>
          {children}
          {!isPlaying && (
            <div className={classes.videoplayer__modal_overlay}>
              <EntityMediaContent mediaContent={mediaContent} media={media} />
            </div>
          )}
          <CloseIcon className={classes.videoplayer__modal_close} onClick={onClose} />
        </div>
      </div>
    </ReactPortal>
  );
};

const VideoPlayer = ({
  media,
  mediaContent,
  muted = false,
  inline = false,
  onPlay,
  onPause,
  lazy = true,
  useIntersectionObserver = true,
  height = 170,
  width = 303,
  preload = false,
  isPlaying: isControlledPlaying = null,
  showVideoInfo = false,
  fetchpriority,
  onClose,
  trackingData,
  attributionClassName,
}) => {
  const track = useTracking();
  const { url, thumbnail } = useMemo(() => {
    const mediaURL = media?.url || media?.file?.urls.original;

    if (!mediaURL) {
      return {};
    }

    let videoAttributeType = '';
    if (showVideoInfo) {
      videoAttributeType = media?.attributes?.find(
        attribute => attribute?.attributeType?.slug === VIDEO_ATTRIBUTE_SLUGS.VIDEO_TYPE,
      )?.name;
    }

    const videoURL = new URL(mediaURL);
    if (!mediaURL?.match(cueTvMediaUrlRegex)) {
      videoURL.searchParams.append('rel', 0);
    }

    const urls = media?.thumbnail?.urls;
    const videoLink = videoURL.href;

    return {
      url: videoLink,
      thumbnail: urls?.large || urls?.medium || urls?.small || urls?.original,
      videoType: videoAttributeType,
    };
  }, [media, showVideoInfo]);

  const [isPlayedOnce, setIsPlayedOnce] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const getTrackingData = () => ({
    media_id: media?.id,
    production_id: media?.production?.id,
    location: VIDEO_ACTION_BUTTONS.VIDEOS,
    production_name: media?.production?.name,
    video_url: url,
  });
  const isControlledPlayer = useMemo(() => isControlledPlaying !== null, [isControlledPlaying]);

  const onPlayHandler = useCallback(() => {
    if (!isPlayedOnce) {
      setIsPlayedOnce(true);
    }
    if (!inline) {
      setIsExpanded(true);
    }
    setShowPreview(false);
    setIsPlaying(true);

    if (onPlay) {
      onPlay();
    }

    track.click(trackVideoClick(VIDEO_ACTION_BUTTONS.WATCH, { ...getTrackingData() }), GOOGLE_OLD_TRACKING_SERVICES);
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.VIDEO_PLAYER,
      meta: {
        productionId: media?.production?.id,
        productionName: media?.production?.name,
        mediaId: media?.id,
        videoUrl: url,
        ...trackingData?.meta,
      },
    });
  }, [onPlay, inline, isPlayedOnce, isControlledPlayer]);

  const onPauseHandler = useCallback(() => {
    setShowPreview(!!thumbnail);
    setIsPlaying(false);

    if (onPause) {
      onPause();
    }
  }, [onPause, thumbnail, isControlledPlayer]);

  useEffect(() => {
    if (isControlledPlayer) {
      if (isControlledPlaying) {
        onPlayHandler();
      } else if (isPlaying) {
        onPauseHandler();
      }
    }
  }, [isControlledPlaying]);

  const onCloseHandler = useCallback(
    event => {
      if (event) {
        event.stopPropagation();
      }
      setIsExpanded(false);
      onPauseHandler();
      if (typeof onClose === 'function') {
        onClose();
      }
    },
    [onClose, onPauseHandler],
  );

  const player = (
    <>
      {isPlayedOnce && (
        <div className={classes.player}>
          <ReactPlayerWrapper
            url={url}
            playing={isPlaying}
            light={false}
            muted={muted}
            onClickPreview={onPlayHandler}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            onEnded={onPauseHandler}
            playIcon={<PlayCircleOutlineIcon className={classes.playIcon} />}
          />
        </div>
      )}
    </>
  );

  const imageProps = {
    src: thumbnail,
    blurHash: media?.blurHash,
    transformations: TRANSFORMATIONS.VIDEO_CONTENT_AWARE_THUMBNAIL,
    alt: media?.title || '',
    title: media?.title || '',
    height,
    width,
    lazy,
    preload,
    useIntersectionObserver,
    fetchpriority: 'high',
    disableNextImage: true,
  };

  const preview = (
    <>
      <div
        className={classnames(classes.preview, { [classes.isPlaying]: !showPreview })}
        onClick={() => !isControlledPlayer && onPlayHandler()}
      >
        {thumbnail && (
          <>
            <noscript className={classes.noscriptImage}>
              <Image
                src={thumbnail}
                blurHash={media?.blurHash}
                transformations={TRANSFORMATIONS.VIDEO_CONTENT_AWARE_THUMBNAIL}
                height={height}
                width={width}
                lazy={lazy}
                fetchpriority={fetchpriority}
                disableNextImage
              />
            </noscript>
            <MediaCard
              imageProps={imageProps}
              attribution={{ source_info: media?.source_info, credit: media?.credit, copyright: media?.copyright }}
              attributionClassName={attributionClassName}
              {...(showVideoInfo && { title: media?.title })}
            />
          </>
        )}
        <div className={classes.videoOverlay}>
          <PlayCircleOutlineIcon className={classes.playIcon} />
        </div>
      </div>
    </>
  );

  const shouldShowPreview = inline ? showPreview && !isPlayedOnce : true;

  return (
    <div className={classes.root}>
      {shouldShowPreview && preview}
      {inline && player}
      {!inline && isExpanded && (
        <VideoPlayerModal isPlaying={!showPreview} onClose={onCloseHandler} mediaContent={mediaContent} media={media}>
          {player}
        </VideoPlayerModal>
      )}
    </div>
  );
};

export default VideoPlayer;
